import React from "react"
import {Link} from "gatsby"
const Navbar = () => {
    return (
        
        <nav style={{paddingBottom: '15px'}}>
                
        <span style={{marginLeft: '20px'}}><Link to="/blog">Writing</Link></span>

        <span style={{marginLeft: '20px'}}><Link to="/projects">Projects</Link></span>

        <span style={{marginLeft: '15px'}}> <Link target="_blank"  to="https://seekingclarity.substack.com/">Newsletter</Link></span>
        
       </nav>
        
        
    )
}

export default Navbar