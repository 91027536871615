import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Navbar from '../components/navbar'

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
      
          <h3
          style={{
            
            marginBottom: 10,
            marginTop: 0, display:'flex', 
            flexdirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'flex-start', height: '100%',
            
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `black`, /* big Sean Cheng Name bit*/
              fontSize: '40px'
            }}
            to={location.pathname === blogPath ? `/blog/` : `/`}
          >
            {title}
          </Link>
          <h5>
          <Navbar/>
          </h5>
          </h3>

      
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
            display:'flex', flexdirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginright: 'auto' 
            
          }}
        >
          <Link /*about me button and name link*/
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: 'black',
              fontSize: '40px',
              /*background: `linear-gradient(35deg, maroon, gold)`*/
            }}
            to={`/`}
          >
            {title}
          </Link>
          <h5>
        <Navbar/>
        </h5>
        </h3>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer>
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
